import axios from 'axios'

export default {
  checkPermission(pName: any, actions: any, subPage?: any) {

    const profile = JSON.parse(localStorage.getItem('profile'))
    const allPermissions = JSON.parse(localStorage.getItem('permission'))

    if (profile !== null) {
      if (profile.role_id !== 2) {
        const filterpermisssion = allPermissions.filter((itm: any) => {
          return itm.name === pName
        })

        let filterResult
        if (subPage) {
          filterResult = filterpermisssion[0].data[subPage].filter((itm: any) => {
            return itm.name === pName && itm.type === actions
          })
        } else {
          filterResult = filterpermisssion[0].data.filter((itm: any) => {
            return itm.name === pName && itm.type === actions
          })
        }
        return filterResult[0].value
      } else {
        return true
      }
    } else {
      return false
    }
  },

  getAllCountries() {
    return axios.get('countries')
  },
}
