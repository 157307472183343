import axios from 'axios'

export default {
  getAllCandidates(data: any) {
    return axios.post('candidates', data)
  },

  candidateReferenceRequest(data: any) {
    return axios.post('reference-request/add', data)
  },

  getCandidateDetails(id: any) {
    return axios.get('candidate/' + id)
  },

  deleteCandidate(id: any) {
    return axios.put('candidate/' + id + '/delete')
  },

  sendCandidateRemainder(id: any) {
    return axios.post('candidate/' + id + '/reminder')
  },

  getRefereeDetails(id: any) {
    return axios.get('referee/' + id)
  },

  getRefereeDetailsPDF(id: any) {
    return axios.get('referee/' + id + '/export_pdf')
  },

  getCandiateDetailsPDF(id: any) {
    return axios.get('candidate/' + id + '/export_pdf')
  },

  getAllQuestionProfiles() {
    return axios.get('profiles')
  },

  getcandidateQuestionProfiles(id: any) {
    return axios.get('candidate/' + id + '/profiles')
  },

  getCandidateTimeline(id: any) {
    return axios.get('candidate/' + id + '/timeline')
  },

  addNewReferee(id: any, data: any) {
    return axios.post('candidate/' + id + '/referee-add', data)
  },

  addNewRefereewithToken( data: any) {
    return axios.post('candidate/referee-add', data)
  },

  deleteReferee(id: any) {
    return axios.put('referee/' + id + '/delete')
  },

  getWebRefereeDetails(data: any) {
    return axios.post('get-referee', data)
  },

  updateWebRefereeDetails(data: any) {
    return axios.put('edit-referee', data)
  },

  getWebRefereeQuestions(data: any) {
    return axios.post('get-questions', data)
  },

  saveWebRefereeAnswers(data: any) {
    return axios.post('answers', data)
  },

  getWebCandidateDetails(data: any) {
    return axios.post('get-candidate', data)
  },

  getWebCandidateId(data: any) {
    return axios.post('get-candidate-id', data)
  },

  webCandidateAddReferee(data: any) {
    return axios.post('candidate/referee-add', data)
  },

  webCandidateDeleteReferee(data: any) {
    return axios.post('candidate/referee-delete', data)
  },

  refereeRemainder(id: any) {
    return axios.post('referee/' + id + '/reminder')
  },

  // userServices

  getAllUser() {
    return axios.get('users')
  },

  addNewUser(data: any) {
    return axios.post('register', data)
  },

  editUser(id: any, data: any){
    return axios.put('user/'+ id, data)
  },
   
  deleteUser(id: any) {
    return axios.put('user/' + id + '/delete')
  },

  getUserPermission (id: any) {
    return axios.get('permissions/' + id)
  },

  addUserPermission(data: any) {
    return axios.post('permission/user-permission', data)
  },

  updateContactPerson(id: any, data: any) {
    return axios.put('agency/'+ id +'/update-contact-person', data)
  }
}
