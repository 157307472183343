import axios from 'axios'

export default {
  getQProfile ()  {
    return axios.get('profiles')
  },

  addQProfile (data: any)  {
    return axios.post('profile/add', data)
  },

  deleteQProfile (id: any)  {
    return axios.put('profile/' + id + '/delete')
  },

  getAllQuestions (id: any)  {
    return axios.get('profile/' + id + '/questions')
  },

  addNewQuestion (id: any, data: any)  {
    return axios.post('profile/' + id + '/add-question', data)
  },

  deleteQuestion (id: any)  {
    return axios.put('question/' + id + '/delete')
  },

  editQuestion (id: any, data: any)  {
    return axios.put('question/' + id + '/edit', data)
  },
}
