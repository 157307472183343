import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Login.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/Signup.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/referee_details/:id',
    name: 'Referee Details',
    component: () => import('../views/refereeDetails.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/billing',
    name: 'Billing',
    component: () => import('../views/billing.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/questions',
    name: 'Questions Profile',
    component: () => import('../views/questionProfile.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/userManagement.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/userProfile.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  },
  // refereeWebPage
  {
    path: '/referee/:token',
    name: 'Referee',
    component: () => import('../views/referee.vue'),
    meta: {
      guest: true
    }
  },
  // candidateWebPage
  {
    path: '/candidate/:token',
    name: 'Candidate',
    component: () => import('../views/candidate.vue'),
    meta: {
      guest: true
    }
  },
  // candidate > refeee WebPage
  {
    path: '/add-referee/:token',
    name: 'Candidate Referee',
    component: () => import('../views/candidateRefere.vue'),
    meta: {
      guest: true
    }
  },
  //authorize app
  {
    path: '/oauth/authorize',
    name: 'Authorize',
    component: () => import('../views/authorize.vue'),
    meta: {
      // requiresAuth: true,
      // isAdmin: true,
      guest: true
    }
  },
  // Super Admin > Agencies
  {
    path: '/admin-agencies',
    name: 'Agencies',
    component: () => import('../views/super-admin/agency.vue'),
    meta: {
      requiresAuth: true,
      isSUperAdmin: true
    }
  },
  // Super Admin > Credits
  {
    path: '/admin-prices',
    name: 'Prices',
    component: () => import('../views/super-admin/price.vue'),
    meta: {
      requiresAuth: true,
      isSUperAdmin: true
    }
  },
  {
    path: '/admin-agency/:id',
    name: 'Agency Details',
    component: () => import('../views/super-admin/agencyDetails.vue'),
    meta: {
      requiresAuth: true,
      isSUperAdmin: true
    }
  },
  // Super Admin > Organisations
  {
    path: '/admin-organisations',
    name: 'Organisation',
    component: () => import('../views/super-admin/organisations.vue'),
    meta: {
      requiresAuth: true,
      isSUperAdmin: true
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'Page Not Found',
    component: () => import('../views/404.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/no_access',
    name: 'No Access',
    component: () => import('../views/NoAccess.vue'),
    meta: {
      requiresAuth: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('accessToken') == null) {
      next({ name: 'Login' })
    } else {
      const profile = JSON.parse(localStorage.getItem('profile') || '{}')
      if (to.matched.some(record => record.meta.isAdmin)) {
        if (profile.role_id === 2 || profile.role_id === 3 ) {
          next()
        } else {
          next({ name: 'No Access' })
        }
      } else if ( to.matched.some(record => record.meta.isSUperAdmin)) {
        if (profile.role_id === 1) {
          next()
        } else {
          next({ name: 'No Access' })
        }
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
