import { createApp, ref } from 'vue'
import { Layout, Row, Col, Select, Input, InputNumber, Table, Tabs, Tag, Button, Breadcrumb, PageHeader, Pagination, Form, Menu, Avatar, Image, Checkbox, Radio, Progress, Upload, Modal, Steps, Empty, Divider, DatePicker, TimePicker, Switch, Collapse, Drawer, List, Tooltip, Popconfirm, Spin, Badge, Timeline, Dropdown, notification, Rate } from 'ant-design-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'ant-design-vue/dist/antd.css'
import authServices from "./services/auth";
// import 'vue-suggestion/dist/vue-suggestion.css';
// import VueSuggestion from 'vue-suggestion';
import { useRoute } from "vue-router";

createApp(App)
.use(Row)
  .use(store)
  .use(router)
  .use(Col)
  .use(Layout)
  .use(Select)
  .use(Input)
  .use(InputNumber)
  .use(Table)
  .use(Tabs)
  .use(Tag)
  .use(Button)
  .use(Breadcrumb)
  .use(PageHeader)
  .use(Pagination)
  .use(Form)
  .use(Menu)
  .use(Avatar)
  .use(Progress)
  .use(Image)
  .use(Radio)
  .use(Checkbox)
  .use(Upload)
  .use(Modal)
  .use(Steps)
  .use(Empty)
  .use(Divider)
  .use(DatePicker)
  .use(TimePicker)
  .use(Switch)
  .use(Collapse)
  .use(Drawer)
  .use(List)
  .use(Tooltip)
  .use(Popconfirm)
  .use(Spin)
  .use(Badge)
  .use(Timeline)
  .use(Dropdown)
  .use(Rate)
  .use(VueAxios, axios)
  // .use(VueSuggestion)
  .mount('#app')

axios.defaults.timeout = 20000
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.interceptors.request.use((config: any) => {
  const tokenType = localStorage.getItem('tokenType')
  const token = localStorage.getItem('accessToken')
  if (tokenType && token) {
    config.headers.common.Authorization = tokenType + ' ' + token
  }
  return config
}, (error: any) => {
  return Promise.reject(error)
})

const notify = async (
  message: string,
  description: string,
  type: string
) => {
  (notification as any)[type]({
    message,
    description,
  });
};
const route = useRoute();
const unathorised = ref(false)
axios.defaults.params = {};
axios.interceptors.response.use((response: any) => {
  return response
}, (error: any) => {
  // console.log('error', error.response.request.responseURL)  
  if (error.response.data.message === "Unauthorized") {
    if (unathorised.value === false) {
      unathorised.value = true
      notify('Info', 'Session Expired, please Log in again.', 'info')
      router.push("/login");
      delete localStorage.accessToken
      delete localStorage.profile
      localStorage.clear()
      setTimeout(() => {
        unathorised.value = false
      }, 2000)
    }
  } else if(error.response.data.message === "authorize redirect"){
    if (unathorised.value === false) {
      let redirect_uri:any;
      let email:any;
      let client_id:any;
      let slug:any;
      const urlString = error.response.request.responseURL;
      const paramString = urlString.split('?')[1];
      const queryString = new URLSearchParams(paramString);
      for (const pair of queryString.entries()) {
        if(pair[0] == 'redirect_uri'){
         redirect_uri = pair[1];
        }
        if(pair[0] == 'email'){
          email = pair[1];
        }
        if(pair[0] == 'client_id'){
          client_id = pair[1];
        }
        if(pair[0] == 'subdomain'){
          slug = pair[1];
        }
      }
      unathorised.value = true
      notify('Info', 'Session Expired, please Log in again.', 'info')
      router.push("/login?redirect=true");
      delete localStorage.accessToken
      delete localStorage.profile
      localStorage.clear()
      setTimeout(() => {
        unathorised.value = false
        localStorage.setItem('redirect_uri', redirect_uri);
        localStorage.setItem('logezy_email', email);
        localStorage.setItem('client_id', client_id);
        localStorage.setItem('subdomain', slug);
      }, 2000)
    }
  }
  return Promise.reject(error)
})

