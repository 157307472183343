import axios from 'axios'

export default {
  login (data: any) {
    return axios.post(process.env.VUE_APP_USER_LOGIN, data)
  },
  fetchtenants (data: any) {
    return axios.post(process.env.VUE_APP_USER_LOGEZY_FETCH, data)
  },
  signinwithlogezy (data: any) {
    return axios.post(process.env.VUE_APP_USER_LOGEZY_LOGIN, data)
  },
  logezycandidates () {
    const agency = JSON.parse(localStorage.getItem('profile'));
    return axios.post(process.env.VUE_APP_USER_LOGEZY_CANDIDATES, agency)
  },

  register (data: any) {
    return axios.post(process.env.VUE_APP_USER_REGISTER, data)
  },

  getProfile () {
    return axios.get('profile')
  },

  forgotPassword (data: any) {
    return axios.post(process.env.VUE_APP_USER_FORGOT_PASS, data)
  },

  logout () {
    return axios.post('logout')
  },

  loggedIn () {
    return !!localStorage.accessToken
  },

  editUserprofile (data: any) {
    return axios.put('edit-profile', data)
  },

  profileUpload ( data: any) {
    return axios.put('edit-profile-photo', data)
  },

  uploadImage (data: any) {
    return axios.post('upload-image', data)
  },

  updateAgency (id: any, data: any)  {
    return axios.put('agency/' + id , data)
  },
}
